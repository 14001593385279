jQuery(function ($) {
    //disable scroll script on open menu
    var lastWindowScrollTop = 0,
        userAgent = navigator.userAgent,
        $body = $("body"),
        isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                userAgent
            ),
        NO_SCROLL_CLASS = isMobile ? "ios-noscroll" : "non-ios-noscroll";

    function fixedBody() {
        if (isMobile) {
            lastWindowScrollTop = $(window).scrollTop();
            $body.addClass(NO_SCROLL_CLASS);
            if ($('body.admin-bar').length === 0) {
                $body.css("top", "-" + lastWindowScrollTop + "px");
            }
            else {
                $body.css("top", "0px");
            }
        } else {
            $body.addClass(NO_SCROLL_CLASS);
        }
    }

    function looseBody() {
        $body.removeClass(NO_SCROLL_CLASS);
        if (isMobile) {
            $body.css("top", "");
            window.scrollTo(0, lastWindowScrollTop);
        }
    }

    $.fn.scrollableOverlay = function () {
        this.on("show", fixedBody);
        this.on("hide", looseBody);
    };

    //menu

    //fire function to check if open or closed to fix scrolling
    $("#menuPage").scrollableOverlay();

    $("body").on("click", ".burger", function (event) {
        event.preventDefault();
        // $('#menuPage').toggleClass('mobileOpen');
        // $('#page').toggleClass('mobileOpen');

        $(this).toggleClass("active");

        if ($(this).hasClass("active")) {
            // $('#menuPage').removeClass('hidden');

            $("#logo").addClass("gone");

            $("#menuPage").trigger("show");
            // $('#menuPage').slideDown();

            $("#menuPage").slideDown(500, function () {

                // Fade in the child elements
                $("#menuPage").css("display", "block");
                $(this)
                    .find(".primary-menu")
                    .each(function (index) {
                        $(this)
                            .delay(index * 100)
                            .fadeIn(500);
                    });

                $(this)
                    .find(".appendedMenu")
                    .each(function (index) {
                        $(this)
                            .delay(index * 100)
                            .fadeIn(500);
                    });

                // $(this)
                //     .find(".mobile-menu-footer")
                //     .each(function (index) {
                //         $(this)
                //             .delay(index * 100)
                //             .fadeIn(500);
                //     });
                $('body').delay(500).toggleClass('mobile-menu-open');


            });
        } else {
            // $('#menuPage').addClass('hidden');
            $("#menuPage").trigger("hide");

            $("#logo").removeClass("gone");

            $(".appendedMenu").fadeOut(100);

            $("#menu-primary").fadeOut(100, function () {
                $("#menuPage").slideUp(500, function () {
                    // $('#menuPage').css('display','flex')
                });
            });

            $('body').delay(100).toggleClass('mobile-menu-open');
        }
    });

    //menu click events

    $("body").on("click", ".preventDefault", function (event) {
        event.preventDefault();
    });

    $("body").on("click", ".heading h3", function (event) {
        if ($(window).width() < 991) {
            event.preventDefault();
        }
        $(this).parent().next(".sub-menu.mobile").slideToggle();
        $("body").toggleClass('mobile-submenu-open');
    });

    $("body").on("click", "a.menu-item-has-children", function (event) {
        if ($(window).width() < 991) {
            event.preventDefault();
        }

        $(this).next(".sub-menu.mobile").slideToggle();
        $("body").toggleClass('mobile-submenu-open');
    });

    //mobile menu click to open
    if ($(window).width() < 991) {
        $("a.menu-item-has-children").next(".sub-menu").addClass("mobile");
        $(".heading h3").parent().next(".sub-menu").addClass("mobile");

        $(".heading h3 a").addClass("preventDefault");
    }

    var resizeTimeout;
    $(window).scroll(function () {

        clearTimeout(resizeTimeout); // Workaround for iOS Safari firing scroll and resize too many times. See: https://stackoverflow.com/a/45617830.
        resizeTimeout = setTimeout(function() {
            // Get the current scroll position
            var $logo = $("#logo");

            // Define the threshold after which the logo should slide up
            var scrollThreshold = 100;

            if ($(window).width() < 576) {
                var scrollPosition = $(window).scrollTop();

                // Check if the scroll position is greater than the threshold
                if (scrollPosition > scrollThreshold) {
                    // Apply slide-up animation to the logo element
                    $logo.slideUp();
                } else {
                    // If the scroll position is at or above the threshold, show the logo
                    $logo.slideDown();
                }
            }
        }, 10);
    });

    // Site header + WordPress adminbar handling  
    if ($(window).width() < 992) {
        var wpAdminBar = $('#wpadminbar');
        
        if (wpAdminBar.length > 0) {
            var siteHeader = $('#header');

            $(window).on("scroll", function() {
                var windowWidth = $(window).width();
                var scrollPosition = $(window).scrollTop();
                var wpAdminBarHeight = wpAdminBar.css('height');

                if (windowWidth > 782 && windowWidth < 992) {
                    siteHeader.css({'top': wpAdminBarHeight + 'px'});
                }
                else if (windowWidth > 575 && windowWidth <= 782) {
                    if (scrollPosition === 0)
                        siteHeader.css({'top': wpAdminBarHeight + 'px'});
                    else if (scrollPosition < wpAdminBarHeight)
                        siteHeader.css({'top': wpAdminBarHeight + 'px'});
                    else
                        siteHeader.css({'top': ''});
                }
                else if (windowWidth <= 575) {
                    if (scrollPosition === 0)
                        siteHeader.css({'top': ''});
                    else if (scrollPosition < wpAdminBarHeight)
                        siteHeader.css({'top': '0px'});
                    else
                        siteHeader.css({'top': '0px'});
                }
            });
        }
    }

    //close menu on resize
    $(window).on("resize", function () {
        var win = $(this); //this = window

        // $(".sub-menu").removeAttr("style");
        // $("#menu-primary").removeAttr("style");
        // $(".appendedMenu").removeAttr("style");
        // $(".burger").removeClass("active");
        // $("#menuPage").removeAttr("style");


        var resizeTimeout;
        $(window).scroll(function () {

            clearTimeout(resizeTimeout); // Workaround for iOS Safari firing scroll and resize too many times. See: https://stackoverflow.com/a/45617830.
            resizeTimeout = setTimeout(function() {
                // Get the current scroll position
                var $logo = $("#logo");

                // Define the threshold after which the logo should slide up
                var scrollThreshold = 100;

                if ($(window).width() < 576) {
                    var scrollPosition = $(window).scrollTop();

                    // Check if the scroll position is greater than the threshold
                    if (scrollPosition > scrollThreshold) {
                        // Apply slide-up animation to the logo element
                        $logo.slideUp();
                    } else {
                        // If the scroll position is at or above the threshold, show the logo
                        $logo.slideDown();
                    }
                }
            }, 10);
        });

        if (win.width() > 991) {
            // $('#menuPage').trigger('show')
            $("#menuPage").removeAttr("style");
            $(".sub-menu").removeAttr("style");
            $("#menu-primary").removeAttr("style");
            $(".appendedMenu").removeAttr("style");
            $(".burger").removeClass("active");

            $("body").removeClass("ios-noscroll");

            $("a.menu-item-has-children")
                .next(".sub-menu")
                .removeClass("mobile");
            $(".heading h3").parent().next(".sub-menu").removeClass("mobile");

            $(".heading h3 a").removeClass("preventDefault");
        } else {
            $("a.menu-item-has-children").next(".sub-menu").addClass("mobile");
            $(".heading h3").parent().next(".sub-menu").addClass("mobile");

            $(".heading h3 a").addClass("preventDefault");
        }
    });
});
