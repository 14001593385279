import { Loader } from "@googlemaps/js-api-loader";

//swiper js
import Swiper from "swiper/bundle";
//swiper css
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

require("./menu.js");
// require('./miniCart.js');
require("./addToCart.js");

var loader = new Loader({
    apiKey: site_data.google_api_key,
    version: "weekly",
    libraries: ["places"],
});

jQuery(function ($) {
    // document.addEventListener('touchstart', onTouchStart, {passive: true});

    //reviewSlider

    if ($(".reviewSlider-wrapper").length) {
        const swiperReview = new Swiper(".reviewSlider-wrapper", {
            speed: 400,
            autoplay: {
                delay: 4000,
            },
            loop: false,
            slidesPerView: 3,
            spaceBetween: 100,
            watchOverflow: true,
            navigation: {
                nextEl: ".reviewNext",
                prevEl: ".reviewPrev",
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1280: {
                    slidesPerView: 3,
                },
            },
        });
    }

    if ($(".relatedSwiper").length) {
        var relatedSwiper = new Swiper(".relatedSwiper", {
            watchOverflow: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preventInteractionOnTransition: true,
            spaceBetween: 40,
            navigation: {
                nextEl: ".related-swiper-button-next",
                prevEl: ".related-swiper-button-prev",
            },
            pagination: {
                el: ".related-swiper-pagination",
                type: "bullets",
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
                768: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 4,
                },
            },
        });
    }

    if ($(".relatedSwiper2").length) {
        var relatedSwiper2 = new Swiper(".relatedSwiper2", {
            watchOverflow: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preventInteractionOnTransition: true,
            spaceBetween: 40,
            navigation: {
                nextEl: ".related2-swiper-button-next",
                prevEl: ".related2-swiper-button-prev",
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
                768: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 4,
                },
            },
        });
    }

    if ($(".productItemSlider-wrapper").length) {
        const productItemSlider = document.querySelectorAll(
            ".productItemSlider-wrapper"
        );
        var i = 0;

        for (i = 0; i < productItemSlider.length; i++) {
            var first = productItemSlider[i].nextElementSibling;
            var second = first.nextElementSibling;
            var third = second.nextElementSibling;

            productItemSlider[i].classList.add(
                "productItemSlider-wrapper-" + i
            ); //slider
            first.classList.add("productItemSlider-pagination-" + i); //pagination
            second.classList.add("productItemSlider-button-next-" + i); //next
            third.classList.add("productItemSlider-button-prev-" + i); //previous

            var slider = new Swiper(".productItemSlider-wrapper-" + i, {
                speed: 1000,
                // slidesPerView: 4,
                spaceBetween: 20,
                direction: "horizontal",
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: true,
                    reverseDirection: false,
                },
                navigation: {
                    nextEl: ".productItemSlider-button-next-" + i,
                    prevEl: ".productItemSlider-button-prev-" + i,
                },
                breakpoints: {
                    1280: {
                        slidesPerView: 4,
                    },
                    991: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    0: {
                        slidesPerView: 1,
                    },
                },
            });
        }
    }

    if ($(".textimageSlider-wrapper").length) {
        const textimageSlider = document.querySelectorAll(
            ".textimageSlider-wrapper"
        );
        var i = 0;
        var imageSlider = {};

        for (i = 0; i < textimageSlider.length; i++) {
            var first = textimageSlider[i].nextElementSibling;
            var second = first.nextElementSibling;
            var third = second.nextElementSibling;

            textimageSlider[i].classList.add("textimageSlider-wrapper-" + i); //slider
            first.classList.add("textimageSlider-pagination-" + i); //pagination
            second.classList.add("textimageSlider-button-next-" + i); //next
            third.classList.add("textimageSlider-button-prev-" + i); //previous

            imageSlider[i] = new Swiper(".textimageSlider-wrapper-" + i, {
                speed: 400,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
                slidesPerView: 1,
                direction: "horizontal",
                loop: true,
                simulateTouch: false,
                autoplay: false,
                navigation: {
                    nextEl: ".textimageSlider-button-next-" + i,
                    prevEl: ".textimageSlider-button-prev-" + i,
                },
            });
        }

        var count = 0;

        $.each(imageSlider, function (index, value) {
            imageSlider[index].on("slideChange", function () {
                var slideIndex = this.realIndex + 1;
                contentSlider[index].slideTo(slideIndex);
            });
            count++;
        });
    }

    if ($(".textcontentSlider-wrapper").length) {
        const textcontentSlider = document.querySelectorAll(
            ".textcontentSlider-wrapper"
        );
        var i = 0;
        var contentSlider = {};

        for (i = 0; i < textcontentSlider.length; i++) {
            var first = textcontentSlider[i].nextElementSibling;
            var second = first.nextElementSibling;
            var third = second.nextElementSibling;

            textcontentSlider[i].classList.add(
                "textcontentSlider-wrapper-" + i
            ); //slider
            first.classList.add("textcontentSlider-pagination-" + i); //pagination
            second.classList.add("textcontentSlider-button-next-" + i); //next
            third.classList.add("textcontentSlider-button-prev-" + i); //previous

            contentSlider[i] = new Swiper(".textcontentSlider-wrapper-" + i, {
                speed: 400,
                slidesPerView: 1,
                direction: "horizontal",
                loop: true,
                simulateTouch: false,
                autoplay: false,
                pagination: {
                    el: ".textcontentSlider-pagination-" + i,
                    type: "bullets",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".textcontentSlider-button-next-" + i,
                    prevEl: ".textcontentSlider-button-prev-" + i,
                },
            });
        }

        var count = 0;

        $.each(contentSlider, function (index, value) {
            contentSlider[index].on("slideChange", function () {
                var slideIndex = this.realIndex + 1;
                imageSlider[index].slideTo(slideIndex);
            });
            count++;
        });
    }

    //product modal
    const options = {
        keyboard: true,
        size: "xl",
    };

    document.querySelectorAll(".lightbox-toggle:not(disabled)").forEach((el) =>
        el.addEventListener("click", (e) => {
            e.preventDefault();

            const lightbox = new Lightbox(el, options);

            if ($(el).hasClass("disabled")) {
                // lightbox.show();
            } else {
                lightbox.show();
            }
        })
    );

    var productSliderUpdate = true;

    const productCarousel = $(".productSwiper2"),
        swiperInstance = productCarousel.data("swiper");

    if ($(window).width() < 991) {
        $(".woocommerceFilterSidebar").hide();
        $(".woocommerceFilterSidebar").addClass("mobile");
    }

    if ($(window).width() < 768) {
        $(".lightbox-toggle").addClass("disabled");
    }

    $("body").on("click", ".filterDropdown", function (event) {
        event.preventDefault();
        $(".filterDropdown").toggleClass("open");

        if ($(".filterDropdown").hasClass("open")) {
            $(".woocommerceFilterSidebar").slideDown();
        } else {
            $(".woocommerceFilterSidebar").slideUp();
        }
    });

    $(window).on("resize", function () {
        var win = $(this); //this = window

        if (win.width() < 991) {
            // $(".filterDropdown").removeClass(".open");
            if (!$(".woocommerceFilterSidebar").hasClass("mobile")) {
                $(".woocommerceFilterSidebar").hide();
            }
            $(".woocommerceFilterSidebar").addClass("mobile");
        }

        if (win.width() > 991) {
            $(".woocommerceFilterSidebar").show();
            $(".woocommerceFilterSidebar").removeClass("mobile");
            $(".filterDropdown").removeClass("open");
        }

        if (win.width() < 768) {
            $(".lightbox-toggle").addClass("disabled");
        } else {
            $(".lightbox-toggle").removeClass("disabled");
        }
    });

    $("body").on("click", ".playblogvideo", function (event) {
        event.preventDefault();
        console.log("clicked");
        $(this).parent().parent(".thumbnailOverlay").hide();
        $(this).parent().parent(".thumbnailOverlay").next("iframe")[0].src +=
            "&autoplay=1";
    });

    //play video from src
    autoPlayYouTubeModal();

    function autoPlayYouTubeModal() {
        var trigger = $(".openVideoModal");
        trigger.click(function () {
            var theModal = $(this).data("bs-target");
            var videoSRC = $(this).data("src");

            var videoSRCauto =
                videoSRC +
                "?autoplay=1&mute=0&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1";
            $(theModal + " iframe").attr(
                "allow",
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            );
            $(theModal + " iframe").attr("src", videoSRCauto);

            $(theModal).on("hidden.bs.modal", function (e) {
                $(theModal + " iframe").attr("src", "");
            });
        });
    }

    var currentPageURL = window.location.href;
    if (currentPageURL.includes("dealers")) {
        loader.load().then(function (google) {
            if ($("#map").length) {
                initSearch(google);
                initMap(google);
            }

            if ($("#map2").length) {
                initSearch2(google);
                initMap2(google);
            }
        });
    }

    //Consent Magic footer pop-up
    $("body").on("click", ".tessellateConsent", function (event) {
        event.preventDefault();
        $(".cs-info-sticky-button").click();
    });

    $("body").on("click", ".searchButton", function (event) {
        event.preventDefault();
        $(".hiddenSearchForm").toggleClass("open");
    });

    /**
     * Formiddable checkbox overrides
     */
    if ($('section.subscribe').length) {
        var $subscribeSection = $('section.subscribe');
        $subscribeSection.find('.frm_checkbox input[type=checkbox]').after('<span>');
    }
    if ($('section.contact_info').length) {
        var $contactInfoSection = $('section.contact_info');
        $contactInfoSection.find('.frm_checkbox input[type=checkbox]').after('<span>');
    }
});

function initSearch(google) {
    var input = document.getElementById("search-term");
    var autocomplete = new google.maps.places.Autocomplete(input);

    google.maps.event.addListener(autocomplete, "place_changed", function () {
        var place = autocomplete.getPlace();
        if (place.geometry !== undefined) {
            jQuery("#dealers-archive form #lat").val(
                place.geometry.location.lat()
            );
            jQuery("#dealers-archive form #lng").val(
                place.geometry.location.lng()
            );
        } else {
            jQuery("#dealers-archive form #lat").val("");
            jQuery("#dealers-archive form #lng").val("");
        }
    });
}

function initMap(google) {
    var marks = jQuery("#map .marker");
    var setlat = Number(jQuery("#map").data("lat"));
    var setlng = Number(jQuery("#map").data("lng"));

    if (isNaN(setlat) || setlat === undefined) setlat = 0;
    if (isNaN(setlng) || setlng === undefined) setlng = 0;

    var map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: setlat, lng: setlng },
        zoom: 14,
        minZoom: 6,
        maxZoom: 17,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
            {
                featureType: "all",
                elementType: "labels.text",
                stylers: [
                    {
                        color: "#626262",
                    },
                ],
            },
            {
                featureType: "all",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "landscape",
                elementType: "all",
                stylers: [
                    {
                        color: "#f2f2f2",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "all",
                stylers: [
                    {
                        color: "#f5f5f5",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        color: "#c9c9c9",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "all",
                stylers: [
                    {
                        color: "#dae2e4",
                    },
                ],
            },
        ],
    });

    var bounds = new google.maps.LatLngBounds();
    var infowindow = new google.maps.InfoWindow();
    var markers = [];

    marks.each(function () {
        var element = jQuery(this);
        var latlng = new google.maps.LatLng(
            element.data("lat"),
            element.data("lng")
        );
        var mark = new google.maps.Marker({
            position: latlng,
            icon: {
                url: "/wp-content/themes/tessellate_woocommerce/assets/images/marker.png",
                size: new google.maps.Size(30, 36),
                scaledSize: new google.maps.Size(30, 36),
            },
        });

        var text = element.html();

        bounds.extend(latlng);

        if (text !== null && text !== "") {
            google.maps.event.addListener(mark, "click", function () {
                infowindow.close();
                infowindow.setContent(text);
                infowindow.open(map, mark);
            });
        }

        markers.push(mark);

        mark.setMap(map);
    });

    /*
		var cluster = new MarkerClusterer(map, markers, {
			maxZoom: 12,
			gridSize: 10,
			styles: [{
				url: bm.stylesheet_uri + '/images/pins/pin-multi.png',
				textSize: 1,
				height: 50,
				width: 30,
				textColor: '#7668ae'
			}]
		});
	*/

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);

    return map;
}

function initSearch2(google) {
    var input = document.getElementById("search-term2");
    var autocomplete = new google.maps.places.Autocomplete(input);

    google.maps.event.addListener(autocomplete, "place_changed", function () {
        var place = autocomplete.getPlace();
        if (place.geometry !== undefined) {
            jQuery("#dealers-archive form #lat").val(
                place.geometry.location.lat()
            );
            jQuery("#dealers-archive form #lng").val(
                place.geometry.location.lng()
            );
        } else {
            jQuery("#dealers-archive form #lat").val("");
            jQuery("#dealers-archive form #lng").val("");
        }
    });
}

function initMap2(google) {
    var marks = jQuery("#map2 .marker");
    var setlat = Number(jQuery("#map2").data("lat"));
    var setlng = Number(jQuery("#map2").data("lng"));

    if (isNaN(setlat) || setlat === undefined) setlat = 0;
    if (isNaN(setlng) || setlng === undefined) setlng = 0;

    var map = new google.maps.Map(document.getElementById("map2"), {
        center: { lat: setlat, lng: setlng },
        zoom: 14,
        minZoom: 6,
        maxZoom: 17,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
            {
                featureType: "all",
                elementType: "labels.text",
                stylers: [
                    {
                        color: "#626262",
                    },
                ],
            },
            {
                featureType: "all",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "landscape",
                elementType: "all",
                stylers: [
                    {
                        color: "#f2f2f2",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "all",
                stylers: [
                    {
                        color: "#f5f5f5",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        color: "#c9c9c9",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "all",
                stylers: [
                    {
                        color: "#dae2e4",
                    },
                ],
            },
        ],
    });

    var bounds = new google.maps.LatLngBounds();
    var infowindow = new google.maps.InfoWindow();
    var markers = [];

    marks.each(function () {
        var element = jQuery(this);
        var latlng = new google.maps.LatLng(
            element.data("lat"),
            element.data("lng")
        );
        var mark = new google.maps.Marker({
            position: latlng,
            icon: {
                url: "/wp-content/themes/tessellate_woocommerce/assets/images/marker.png",
                size: new google.maps.Size(30, 36),
                scaledSize: new google.maps.Size(30, 36),
            },
        });

        var text = element.html();

        bounds.extend(latlng);

        if (text !== null && text !== "") {
            google.maps.event.addListener(mark, "click", function () {
                infowindow.close();
                infowindow.setContent(text);
                infowindow.open(map, mark);
            });
        }

        markers.push(mark);

        mark.setMap(map);
    });

    /*
		var cluster = new MarkerClusterer(map, markers, {
			maxZoom: 12,
			gridSize: 10,
			styles: [{
				url: bm.stylesheet_uri + '/images/pins/pin-multi.png',
				textSize: 1,
				height: 50,
				width: 30,
				textColor: '#7668ae'
			}]
		});
	*/

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);

    return map;
}
